import React, { useEffect, useState } from 'react';
import Button from 'reactstrap/lib/Button';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import { Loading } from './loadingPage';

const tempBookingOutComeEnum = {
    showMoreInfo: 'moreInfo',
    wantToBook: 'wantToBook',
    alreadyHad: 'alreadyHad',
    notHad: 'notHad',
    planningToHaveSomewhereElse: 'planningToHaveSomewhereElse',
    decline: 'decline',
    talkToClinician: 'talkToClinician',
    talkToManager: 'talkToManager',
    talkToRCE: 'talkToRCE'
}

export const StaffOutcomeSelection = ({ setStaffOutcome, bookingCode, postcode }) => {

    const [tempBookingOutCome, setTempBookingOutCome] = useState();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [finishedDeclined, setFinishedDeclined] = useState(false);
    const [discussedWithManager, setDiscussedWithManager] = useState("");

    const declineReasonDefinition = [
        { text: 'I’m worried about the side effects', code: 'sideEffects', set: false },
        { text: 'I’m worried about the long-term impact on my health', code: 'longTermImpact', set: false },
        { text: 'There isn’t enough research/information available for me to feel confident to have it', code: 'notEnoughResearch', set: false },
        { text: 'I am shielding and do not want to go out to have the vaccine', code: 'shielding', set: false },
        { text: 'My family are not supportive of me having the vaccine', code: 'familyNotSupportive', set: false },
        { text: 'I have religious/faith reasons', code: 'religion', set: false },
        { text: 'I have seen/heard information about the vaccine which concerns me', code: 'seenInformation', set: false },
        { text: 'I have moral/ethical/lifestyle reasons', code: 'moralEthicalLifestyle', set: false },
        { text: 'I am on a research trial for a new COVID vaccine', code: 'researchTrial', set: false },
        { text: 'I am pregnant/breastfeeding', code: 'pregnantOrBreastfeeding', set: false },
        { text: 'I have an allergy', code: 'allergy', set: false },
        { text: 'I have had COVID and believe that protects me. ', code: 'alreadyHadCovid', set: false },
        { text: 'Another reason not listed above', code: 'other', set: false }
    ]

    const [declineReasons, setDeclinedReason] = useState(declineReasonDefinition);

    const submitOutcome = async (outcome, callback) => {
        setLoading(true);

        const response = await fetch(`/api/StaffQuestionsSubmission/${bookingCode}/${postcode}`, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'text/plain'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: outcome // body data type must match "Content-Type" header
        });

        if (!response.ok) {
            setError(true);
        }

        setLoading(false);

        if (callback) {
            callback();
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (tempBookingOutCome && (tempBookingOutComeEnum !== tempBookingOutComeEnum.notHad)) {
            submitOutcome(tempBookingOutCome);
        }
    }, [tempBookingOutCome]);

    const toggleReason = (code) => {
        const next = [...declineReasons]
        const val = next.find(x => x.code === code);
        val.set = !val.set;
        setDeclinedReason(next);
    }

    const submitDeclinedReason = () => {
        var reasonKey = declineReasons.filter(x => x.set).map(x => x.code).join(',');
        reasonKey = `Declined:Discussed-${discussedWithManager},${reasonKey}`;
        submitOutcome(reasonKey, () => { setFinishedDeclined(true) });
    }

    if (error) {
        return <div className="text-white">
            <h3>Something has gone wrong</h3>
            <p>Please refresh the page and try again</p>
            <p>If this is happening constantly please report the problem to the IT Service Desk</p>
        </div>
    }

    if (loading) {
        return <Loading />;
    }

    if (finishedDeclined) {
        return <div className="text-white">
            <p>Thank you for your response. The information available <a rel="noopener noreferrer" target="_blank" href="https://www.guysandstthomas.nhs.uk/coronavirus/know-more-about-the-vaccine.aspx">here</a> - including detailed frequently asked questions, information about the vaccine in different languages, Q&amp;A sessions you can take part in and other support – may help address your concerns.</p>
            <p>Your feedback is important in helping us understand why people may not want to have their vaccine and will be shared with your directorate management team. Your manager may also wish to have a conversation with you.</p>
            <p>Please remember that you can come back to this site to book a vaccine at any time if you change your mind.</p>
        </div>
    }

    if (tempBookingOutCome === tempBookingOutComeEnum.showMoreInfo) {
        return <div className="text-white">
            <h5>
                Thank you for letting us know you’d like more information about the vaccine.
            </h5>
            <p>Please have a look at <a rel="noopener noreferrer" href="https://www.guysandstthomas.nhs.uk/coronavirus/know-more-about-the-vaccine.aspx" target="_blank">this information</a> which includes detailed frequently asked questions, videos in different languages and other support to help you make an informed choice. </p>
            <p>There are also a number of online sessions you can join to help answer any questions you may have. Details of these are available on GTi at gti/vaccine.</p>
            <p>After you’ve reviewed the information please remember to register your decision on the survey. Until you do this you will receive regular reminders and your directorate management team will be aware that you haven’t completed the survey.</p>
            <p>Many thanks for your support.</p>
        </div>
    }

    if (tempBookingOutCome === tempBookingOutComeEnum.talkToRCE) {
        return <div className="text-white">
            <p>Thank you for letting us know you’d like to talk to someone about your religious, cultural, ethical or other non-clinical concerns about the vaccine.</p>
            <p>A member of staff will contact you over the next few days to help answer any questions you have.</p>
            <p>In the meantime please have a look at <a rel="noopener noreferrer" href="https://www.guysandstthomas.nhs.uk/coronavirus/know-more-about-the-vaccine.aspx" target="_blank">this information</a> which includes detailed frequently asked questions, webinars, information about the vaccine in different languages and other support to help you make an informed choice. </p>
            <p>There are also a number of online sessions you can join to help answer any questions you may have. Details of these are available on GTi at gti/vaccine.</p>
            <p>After you’ve had your discussion, and you’ve received to the information you need, please return to the survey to register your decision. Until you do this you will receive regular reminders and your directorate management team will be aware that you haven’t completed the survey.</p>
            <p>Many thanks for your support</p>

        </div>
    }

    if (tempBookingOutCome === tempBookingOutComeEnum.talkToManager) {
        return <div className="text-white">
            <p>Thank you for letting us know you’d like to talk to your line manager about your concerns about the vaccine. Please get in touch with them to arrange your discussion. </p>
            <p>In the meantime please have a look at <a rel="noopener noreferrer" href="https://www.guysandstthomas.nhs.uk/coronavirus/know-more-about-the-vaccine.aspx" target="_blank">this information</a> which includes detailed frequently asked questions, information about the vaccine in different languages and other support to help you make an informed choice. </p>
            <p>There are also a number of online sessions you can join to help answer any questions you may have. Details of these are available on GTi at gti/vaccine.</p>
            <p>After you’ve had your discussion, and you’ve received to all the information you need, please return to the survey to register your decision. Until you do this you will receive regular reminders and your directorate management team will be aware that you haven’t completed the survey.</p>
            <p>Many thanks for your support.</p>
        </div>
    }

    if (tempBookingOutCome === tempBookingOutComeEnum.talkToClinician) {
        return <div className="text-white">
            <p>Thank you for letting us know you’d like to talk to a clinical member of staff about your concerns about the vaccine.</p>
            <p>A member of staff will contact you over the next few days to help answer any questions you have.</p>
            <p>In the meantime please have a look at <a rel="noopener noreferrer" href="https://www.guysandstthomas.nhs.uk/coronavirus/know-more-about-the-vaccine.aspx" target="_blank">this information</a> which includes detailed frequently asked questions, webinars, information about the vaccine in different languages and other support to help you make an informed choice. </p>
            <p>There are also a number of online sessions you can join to help answer any questions you may have. Details of these are available on GTi at gti/vaccine.</p>
            <p>After you’ve had your discussion, and you’ve received all the information you need, please return to the survey to register your decision. Until you do this you will receive regular reminders and your directorate management team will be aware that you haven’t completed the survey.</p>
            <p>Many thanks for your support.</p>
        </div>
    }

    if (tempBookingOutCome === tempBookingOutComeEnum.alreadyHad) {
        return <div className="text-white">
            <h3>Many thanks for having the COVID-19 vaccine.</h3>

            <p>This is our best defence against the virus and can reduce the likelihood of you becoming seriously ill with COVID-19 and transmitting the virus to others.</p>
            <p>Please remember it’s important to have the second dose to ensure lasting protection from COVID-19. </p>
            <p>If you are aware of any colleagues who need further information before making a decision, please direct them to gti/vaccine.</p>
            <h5>You can now close this window.</h5>
        </div>
    }

    if (tempBookingOutCome === tempBookingOutComeEnum.wantToBook) {
        return <div className="text-white">
            <p>Thank you for deciding to have your COVID-19 vaccine. </p>
            <p>You can now book your vaccine appointments online. You will be asked some short screening questions to ensure that it is appropriate for you to receive this vaccine. </p>
            <p>Please be aware that the vaccine is delivered in two doses, so we will also book your second appointment for you at the same time.</p>
            <p>NOTE: This link is unique to you and cannot be used to book appointments for anyone else.</p>
            <Button onClick={() => setStaffOutcome(tempBookingOutComeEnum.wantToBook)} color="success" >Start Booking Process</Button>
        </div>
    }

    if (tempBookingOutCome === tempBookingOutComeEnum.decline) {
        return <div className="text-white">
            <h5>Thank you for informing us that you would not like to receive the vaccine.</h5>
            <p>Please remember that you’re able to book a vaccine at any time if you change your mind.</p>

            <p>Please let us know whether you discussed this decision with a clinician, religious/cultural representative or your manager.</p>
            <Button onClick={() => setDiscussedWithManager('Yes')} color={discussedWithManager === 'Yes' ? 'success' : 'light'} style={{ width: "100%", marginTop: '2px' }}>Yes</Button>
            <Button onClick={() => setDiscussedWithManager('No')} color={discussedWithManager === 'No' ? 'success' : 'light'} style={{ width: "100%", marginTop: '2px', marginBottom: '10px' }} >No</Button>

            <p>We will be very grateful if you can let us know why you’ve decided not to have the vaccine. You can select more than one reason.</p>
            <p>Your response will not be shared with your manager but it will help us understand more about why people have made this decision.</p>

            <div style={{ width: '100%' }}>
                {declineReasons.map((reason, i) => <Button style={{ width: "100%", marginTop: "2px" }} color={reason.set ? 'success' : 'light'} key={i} onClick={() => toggleReason(reason.code)}>{reason.text}</Button>)}
            </div>

            <Button style={{ width: "100%", marginTop: "20px" }} color="success" disabled={declineReasons.filter(x => x.set).length === 0 || !discussedWithManager} onClick={submitDeclinedReason}>Submit My Answers</Button>
        </div>
    }

    if (tempBookingOutCome === tempBookingOutComeEnum.notHad) {
        return <div className="text-white">
            <h5>Thank you for letting us know you’ve not yet had your vaccine.</h5>
            <p>More than 12,000 members of Trust staff, and 20 million people across the country, have already safely received a COVID-19 vaccine. Evidence shows that having the vaccine can reduce the likelihood of you becoming seriously ill with COVID-19 and reduces the risk of you transmitting the virus to others.</p>
            <p>You now have a number of options:</p>
            <h5>1.	Book an appointment</h5>
            <Button onClick={() => setTempBookingOutCome(tempBookingOutComeEnum.wantToBook)} color="success" style={{ width: "100%", marginTop: '5px', marginBottom: '20px' }}>Click here to book online.</Button>
            <h5>2.	Request more information or support.</h5>
            <p>If you remain unsure about whether or not to have the vaccine please let us know how we can help:</p>
            <Button onClick={() => setTempBookingOutCome(tempBookingOutComeEnum.showMoreInfo)} color="success" style={{ width: "100%", marginTop: '5px', marginBottom: '20px' }}>I’d like to read, listen or watch more information about the vaccine</Button>
            <p>Please let us know whether you would like to talk to someone:</p>
            <Button onClick={() => setTempBookingOutCome(tempBookingOutComeEnum.talkToClinician)} color="success" style={{ width: "100%", marginTop: '5px' }}>I’d like to talk to a member of clinical staff about the vaccine</Button>
            <Button onClick={() => setTempBookingOutCome(tempBookingOutComeEnum.talkToRCE)} color="success" style={{ width: "100%", marginTop: '2px' }}>I’d like to talk to someone about my religious, cultural, ethical or other concerns</Button>
            <Button onClick={() => setTempBookingOutCome(tempBookingOutComeEnum.talkToManager)} color="success" style={{ width: "100%", marginTop: '2px', marginBottom: '20px' }}>I’d like to talk to my line manager about the vaccine</Button>
            <h5>3.	Confirm you do not want the vaccine</h5>
            <Button onClick={() => setTempBookingOutCome(tempBookingOutComeEnum.decline)} color="success" style={{ width: "100%", marginTop: '5px' }}>I’ve received all the information and support that I need and have decided not to have the vaccine at this time.</Button>
        </div>
    }

    return <div className="text-white">
        <h5>COVID-19 vaccine uptake survey</h5>
        <p>As the COVID-19 vaccination programme continues across the country, it is really important for us to have the reassurance that everyone who works for Guy’s and St Thomas’ has had the opportunity to have their vaccine, and has access to the information that they need to be able to make an informed choice.</p>

        <p><b>Please confirm whether or not you have received your first dose of the COVID-19 vaccine:</b></p>
        <form>
            <Button onClick={() => setTempBookingOutCome(tempBookingOutComeEnum.alreadyHad)} color="success" style={{ marginTop: '20px', width: '100%' }}>I’ve had my first dose of the vaccine, or have booked an appointment to have it, at the Trust or elsewhere</Button>
            <Button onClick={() => setTempBookingOutCome(tempBookingOutComeEnum.notHad)} color="success" style={{ marginTop: '20px', width: '100%' }}>I’ve not had my COVID-19 vaccine</Button>
        </form>
    </div>
}