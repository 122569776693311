import React from 'react';
import Button from 'reactstrap/lib/Button';

export const Site = ({ sites, setAppointmentSite, failedBooking, appointmentCount }) => {

    return <>
        {failedBooking && <h5>Something went wrong with your booking, please try again:</h5>}
        {!failedBooking && <h5 className="text-white">The named person for this booking is eligible to receive a vaccine at one of our sites.</h5> }
        {sites.length === 0 && <p className="text-white">Unfortunately there are no available slots in the next few weeks, please try again in a few days.</p>}
        {sites.length > 0 && <>
            <p className="text-white">{appointmentCount > 1 ? "On the next pages you will be able to select both the first appointment and second appointment." : "On the next page you will be able to select an appointment." }</p>
            <p className="text-white">Where would you like to be seen?</p>
            {
                sites.filter(x => x !== "Guy's Hospital" || true).sort(() => Math.random()-Math.random()).map((s, i) => <Button color="dark" key={i} block onClick={() => setAppointmentSite(s)}>{s}</Button>)
            }
        </>}
    </>

}