import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Flow } from './components/flow';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <>
        <Switch>
          <Route exact path='/staff/:bookingCode?/:postcode?' children={<Flow isStaff={true} />} />
          <Route exact path='/:bookingCode?/:postcode?' component={Flow} isStaff={false} />
        </Switch>
      </>
    );
  }
}
