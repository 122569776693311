import React, { useState } from 'react';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BookingCode = ({ bookingCode, submitBookingCode, isStaff }) => {
    const [tempBookingCode, setTempBookingCode] = useState(bookingCode || "");

    if (isStaff && !bookingCode) {
        return <>
            <h5 className="text-white">Guy's and St Thomas' COVID-19 vaccine uptake questionnaire</h5>

            <div className="text-white">The link you have used is not valid, please ensure you are using the link exactly as provided on email or text message</div>
        </>
    }

    if (isStaff && bookingCode.length !== 0) {
        return <>
            <h5 className="text-white">Guy's and St Thomas' COVID-19 vaccine uptake questionnaire</h5>
            <hr />
            <p className="text-white">The COVID-19 vaccine protects you and others from the virus. It is important that we know how many of our staff have had the vaccine. Please help us make sure our information is up to date.</p>
            <p className="text-white">This portal will allow you to book a vaccine slot online, request more information if you are undecided, let us know that you’ve already had it or opt-out of the vaccine.</p>
            <p className="text-white">To start we will need to confirm who you are, the link we sent is unique to you, on the next screen we will ask you to confirm your home post code</p>

            <InputGroup className="baseBlue">
                <Button color="success" block onClick={() => submitBookingCode(tempBookingCode)}>Start</Button>
            </InputGroup>

            <p className="text-white">You will not need to provide any other personal information.</p>
            <h5 className="text-white">Thank you for your support.</h5>
        </>
    }

    return <>
        
        <p className="text-white">The COVID-19 vaccination is being offered by Guy's and St Thomas' NHS Foundation Trust and can be booked using this portal.</p>
        <p className="text-white">You can only book an appointment by using the unique booking code that that has been sent to you by text message and/or letter. This code is unique to you or your child and cannot be used to book appointments for anyone else or to reschedule existing appointments.</p>
        <p className="text-white">Please enter your unique booking code in the box below:</p>

        <InputGroup className="baseBlue">
            <Input placeholder="Booking Code" title={tempBookingCode} value={tempBookingCode} onChange={(e) => setTempBookingCode(e.target.value)} maxLength="5"></Input>
            <br /> <br />
            <Button color="dark" block onClick={() => submitBookingCode(tempBookingCode)}>Submit</Button>
        </InputGroup>


        <small className="mt-4">
            <FontAwesomeIcon style={{ color: '#fff' }} icon={faInfoCircle}></FontAwesomeIcon>
            <span className="text-white"> If you are trying to reschedule an existing appointment or are having difficulties using the internet please call us on <a href="tel:020 7188 4040">020 7188 4040</a> and ensure that you have your unique booking code to hand.</span>
        </small>
    </>

}