import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Card } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import CardBody from 'reactstrap/lib/CardBody';
import Container from 'reactstrap/lib/Container';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { Accessibility } from './accessabilityPage';
import { Appointment } from './appointmentPage';
import { BookingCode } from './bookingCodePage';
import { Confirmation } from './confirmationPage';
import { Error } from './errorPage';
import { Loading } from './loadingPage';
import { Postcode } from './postcodePage';
import { Site } from './sitePage';
import { StaffOutcomeSelection } from './staffOutcomeSelectionPage';
import { Warning } from './warningPage';


export const Flow = ({ isStaff }) => {

    const { bookingCode, postcode } = useParams();

    const errors = {
        codeAlreadyUsed: { key: "codeAlreadyUsed", guidanceText: "The unique booking code you have entered cannot be used at this time.", guidanceText1: " This may be because you have already used the code to book an appointment.", guidanceText2: "If you have not already booked an appointment and wish to book one, or if you need to reschedule one of your existing appointments, please telephone 0207 1884040. You will need your unique booking code to hand which you will have received by letter or text message. " },
        detailsDoNotMatch: { key: "detailsDoNotMatch", guidanceText: "The postcode you have entered does not link to the one held in our records. Please press the back button and resubmit the correct postcode.", guidanceText1: "If you have moved house and not updated us you may need to use the postcode from a previous address.", guidanceText2: "If you continue to encounter difficulties, please telephone us on 0207 1884040." },
        notEligible: { key: "notEligible", guidanceText: "Sorry the information provided mean we cannot vaccinate you at this time." },
        somethingGoneWrong: { key: "somethingGoneWrong", guidanceText: "Sorry there is a problem with the portal at the moment, please try again." },
        noAppointments: { key: "noAppointments", guidanceText: "Sorry there are currently no available appointments please try again tomorrow." },
        invalidCode: { key: "invalidCode", guidanceText: "We do not recognise this booking code please try again." },
    }

    const [validatedBookingCode, setValidatedBookingCode] = useState("");
    const [postCode, setPostCode] = useState(postcode || "");
    const [personName, setPersonName] = useState();
    const [validationType, setValidationType] = useState("");
    const [hasNHSNumber, setHasNhsNumber] = useState(false);
    const [appointmentsRequired, setAppointmentsRequired] = useState(2);
    const [appointmentType, setAppointmentType] = useState("");
    const [passedAppointment, setPassedAppointment] = useState(false);
    const [accessibility, setAccessibility] = useState("");
    const [vaxRestriction, setVaxRestriction] = useState("");
    const [appointments, setAppointments] = useState([]);
    const [firstAppointment, setFirstAppointment] = useState();
    const [secondAppointment, setSecondAppointment] = useState();
    const [appointmentSite, setAppointmentSite] = useState();
    const [bookingDetails, setBookingDetails] = useState();
    const [failedBooking, setFailedBooking] = useState(false);
    const [sites, setSites] = useState();
    const [vaccines, setVaccines] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [confirmedWarning, setConfirmedWarnings] = useState(false);
    const [staffOutcome, setStaffOutcome] = useState();
    const [isStaffMember, setIsStaffMember] = useState(false);
    const [weeksBetweenFirstAndSecondAppointments, setWeeksBetweenFirstAndSecondAppointments] = useState();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [help, setHelp] = useState(null);

    const submitBookingCode = async (bookingCode) => {
        setLoading(true);
        var response = await fetch(`/api/BookingVerification/${bookingCode.toUpperCase()}`);
        if (response.ok) {
            var data = await response.json();
            setValidatedBookingCode(bookingCode.toUpperCase());
            setPersonName(data.name);
            setValidationType(data.verificationType);
            setHasNhsNumber(data.hasNHSNumber);
            setAppointmentsRequired(data.appointmentsRequired);
            setAppointmentType(data.appointmentType);
            setIsStaffMember(data.isStaffMember);
            setWeeksBetweenFirstAndSecondAppointments(data.weeksBetweenFirstAndSecondAppointments)
            setLoading(false);
        } else {
            var error = await response.text();
            setError(errors[error] || errors.somethingGoneWrong);
        }
    }

    const submitPostCode = async (postcode) => {
        setLoading(true);
        var response = await fetch(`/api/BookingVerification/${validatedBookingCode}/${validationType}/${postcode}`);
        if (response.ok) {
            var data = await response.text();
            if (data === "false") {
                setError(errors.detailsDoNotMatch);
            }
            setPostCode(postcode);
            setLoading(false);
        } else {
            setError(errors.somethingGoneWrong);
        }
    }

    const getTimeSlots = async (startDate, endDate, restriction, accessibility, locationKeys, filterLocations) => {
        var url = `/api/BookingSlotAvailability/${validatedBookingCode}/${postCode}?StartDate=${startDate.format("YYYY-MM-DD")}&EndDate=${endDate.format("YYYY-MM-DD")}`;

        if (accessibility === "Yes") {
            url += '&accessible=true';
        }

        if (locationKeys) {
            url += '&locationKeys=' + locationKeys.join('|');
        }

        var response = await fetch(url);

        if (response.ok) {
            var data = await response.json();
            if (!data) {
                setError(errors.noAppointments);
            }

            if (restriction) {
                var restrictedVaccines = restriction.split(',');
                const updatedData = [];
                for (let i = 0; i < data.length; i++) {
                    updatedData.push({ ...data[i], slots: data[i].slots.filter(ds => restrictedVaccines.find(rv => rv === ds.vaccine)) });
                }
                return mapAppointments(updatedData, filterLocations);
            }
            else {
                return mapAppointments(data, filterLocations);
            }
        } else {
            setError(errors.somethingGoneWrong);
        }
    }

    const getVaccines = async () => {
        try {
            const response = await fetch('/api/ScheduleVaccine', {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setVaccines(data);
            } else {
                setError(errors.somethingGoneWrong);
            }
        }
        catch (err) {
            console.log(err);
            setError(errors.somethingGoneWrong);
        }
    }

    const loadAppointments = async (incomingRestrictions) => {
        const startDate = new moment().add(1, "day");
        const endDate = new moment().add(3, "weeks");

        let restriction = incomingRestrictions || vaxRestriction;

        if (appointmentType == "Booster" || appointmentType == "Second Booster") {
            let restrictionArray = restriction.split(",");            
            let newRestrictionArray = restrictionArray.filter(item => item == "Pf" || item == "Mo");
            restriction = newRestrictionArray.join(",");                 
        }        

        const appointments = await getTimeSlots(startDate, endDate, restriction, accessibility);
        setAppointments(appointments)
        setSites(appointments.filter(x => x.days.length).map(x => x.site));
    }

    const load = async () =>{
        setLoading(true);
        await loadAppointments(vaxRestriction);
        setLoading(false);
    }

    useEffect(() => {
        getVaccines();
    }, []);

    useEffect(() => {
        if(confirmedWarning){
            load();
        }
    }, [confirmedWarning])

    useEffect(() => {
        if(staffOutcome !== 'book') {
            console.log('POST:' + staffOutcome);
        }
    }, [staffOutcome])

    useEffect(() => {
        var restrictedVaccineCsv = vaccines.filter(v => v.availableForPatientBooking).reduce((agg, cur) => agg === "" ? cur.vaccineCode : (agg + "," + cur.vaccineCode), "")
        setVaxRestriction(restrictedVaccineCsv);
    }, [vaccines])

    const mapAppointments = function (appointments, filterLocations) {
        const sites = [];
        appointments.filter(x => {
            if (!filterLocations) return true;
            return filterLocations.indexOf(x.location) !== -1
        }).forEach(a => {
            if (sites.findIndex(x => x.site === a.campus) === -1) {
                sites.push({ site: a.campus, days: [] });
            }

            const site = sites.find(x => x.site === a.campus);

            a.slots.forEach(slot => {
                if (site.days.findIndex(x => x.date === slot.date) === -1) {
                    site.days.push({ date: slot.date, timeOfDays: [{ timeOfDay: slot.timeOfDay, locations: [a.location], date: slot.date }] });
                } else {
                    const day = site.days.find(x => x.date === slot.date);
                    if (day.timeOfDays.findIndex(tod => tod.timeOfDay === slot.timeOfDay) === -1) {
                        day.timeOfDays.push({ timeOfDay: slot.timeOfDay, locations: [a.location], date: slot.date });
                    } else {
                        const tod = day.timeOfDays.find(tod => tod.timeOfDay === slot.timeOfDay)
                        tod.locations.push(a.location);
                    }
                }
            });
        })

        return sites;
    }

    const firstAppointmentSubmission = async (appointment) => {
        setLoading(true);
        setFirstAppointment(appointment)
        if (appointmentsRequired > 1) {
            const startDate = new moment(appointment.date).add(weeksBetweenFirstAndSecondAppointments, "weeks");
            const endDate = new moment(appointment.date).add(weeksBetweenFirstAndSecondAppointments + 1, "weeks");
            console.log(appointment.locations);
            const timeSlots = await getTimeSlots(startDate, endDate, vaxRestriction, accessibility, null, appointment.locations);
            setAppointments(timeSlots)
            setLoading(false);
        }
        else {
            //setPassedAppointment(true);
            submitRequest({
                site: appointmentSite,
                firstAppointment: appointment,
                secondAppointment: null
            });
        }
    }

    const submitRequest = async (request) => {
        const data = request;
        data.firstAppointment.site = request.site;
        if (data.secondAppointment) {
            data.secondAppointment.site = request.site;
        }
        
        data.bookingCode = validatedBookingCode;
        data.verificationCode = postCode;
        data.appointmentCount = appointmentsRequired;
        data.questions = answers.map(x => { return { questionKey: x.questionKey, answers: [x.answer.value] } });

        const response = await fetch('api/QuestionSubmission', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });

        if (response.ok) {

            var incomingData = await response.json();
            if (incomingData.message) {
                switch (incomingData.message) {
                    case 'NoBookingSlots':
                        setFirstAppointment(null);
                        setSecondAppointment(null);
                        setAppointmentSite(null);
                        setFailedBooking(true);
                        setPassedAppointment(false)
                        await loadAppointments();
                        break;
                    default:
                        setError(errors.somethingGoneWrong);
                        break;
                }

            } else {
                setBookingDetails(incomingData);
                setPassedAppointment(true)
            }
        } else {
            setError(errors.somethingGoneWrong);
        }

        setLoading(false);
    }

    const secondAppointmentSubmission = async (appointment) => {
        setLoading(true);
        setSecondAppointment(appointment)
        //setPassedAppointment(true);
        submitRequest({
            site: appointmentSite,
            firstAppointment: firstAppointment,
            secondAppointment: appointment
        });
    }

    const getCorrectPage = () => {
        if (error) {
            return <Error error={error} />;
        }
        if (loading) {
            return <Loading />;
        }
        if (!validatedBookingCode) {
            return <BookingCode bookingCode={bookingCode} submitBookingCode={submitBookingCode} isStaff={isStaff}/>;
        }

        if (!postCode) {
            return <Postcode submitPostCode={submitPostCode} personName={personName} validationType={validationType} isStaff={isStaffMember} appointmentCount={appointmentsRequired} appointmentType={appointmentType} />;
        }

        if (isStaff && !staffOutcome && (appointmentType !== "Booster" && appointmentType !== "Second Booster")) {
            return <StaffOutcomeSelection setStaffOutcome={setStaffOutcome}  postcode={postCode} bookingCode={bookingCode} />
        }

        if(!accessibility) {
            return <Accessibility setAccessibility={setAccessibility}/>
        }

        const answersWithWarnings = answers.filter(x => x.answer && x.answer.warningText && x.answer.warningText());
        if (!confirmedWarning) {
            return <Warning warnings={answersWithWarnings} confirmation={setConfirmedWarnings} hasNHSNumber={hasNHSNumber} isStaff={isStaffMember} />
        }

        if (!passedAppointment) {
            if (!appointmentSite && sites) {
                return <Site sites={sites.reverse()} setAppointmentSite={setAppointmentSite} failedBooking={failedBooking} appointmentCount={appointmentsRequired} />
            } else if (!firstAppointment) {
                const siteAppointments = appointments.find(x => x.site === appointmentSite);
                if (!siteAppointments || siteAppointments.days.length === 0 ) {
                    return <>
                    <h5 className="text-white">Sorry there are no first appointment slots available for your selected site please try a different site.</h5>
                    <Button block color="warning" onClick={() =>  { setAppointmentSite(null); setFirstAppointment(null); load(); }}>Try Again</Button>
                    </>
                    
                } else {
                    return <>
                        <h3 className="text-white">You have selected <b>{appointmentSite}</b> <Button color="warning" onClick={() =>  { setAppointmentSite(null); setFirstAppointment(null); load(); }}>Change</Button></h3>
                        <h5 className="text-white">Your Appointment</h5>    
                        <Appointment setAppointment={firstAppointmentSubmission} appointments={siteAppointments} />
                    </>
                }
            } else {
                const siteAppointments = appointments.find(x => x.site === appointmentSite);
                if (!siteAppointments || siteAppointments.days.length === 0 ) {
                    return <>
                    <h5 className="text-white">Sorry there are no follow-up slots available for your selected site please try a different site or a different first appointment date.</h5>
                    <Button block color="warning" onClick={() =>  { setAppointmentSite(null); setFirstAppointment(null); load(); }}>Try Again</Button>
                    </>
                } else {
                    return <><h5 className="text-white">Second Appointment</h5><Appointment setAppointment={secondAppointmentSubmission} appointments={siteAppointments} /></>
                }
            }
        }

        if (validatedBookingCode && postCode && passedAppointment) {
            return <Confirmation bookingDetails={bookingDetails} appointmentCount={appointmentsRequired} />;
        }

        return <h2 className="text-white">
            Sorry something has gone wrong on the portal, please refresh your page to try again.
        </h2>
    }

    return (<Container fluid={true} className="BaseCenterContent">
        {help && <Modal isOpen={true}>
            <ModalHeader toggle={() => setHelp(null)}></ModalHeader>
            <ModalBody style={{ whiteSpace: "pre-wrap" }}>
                {help.helpText()}
            </ModalBody>
            <ModalBody>
                <Button block onClick={() => setHelp(null)}>Close</Button>
            </ModalBody>
        </Modal>}
        <Card className="b-0">
            <CardBody className="baseBlue">
                {getCorrectPage()}
            </CardBody>
        </Card>
    </Container>
    )
}