
import React from 'react';
import moment from 'moment';
import Button from 'reactstrap/lib/Button';
import ListGroup from 'reactstrap/lib/ListGroup';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';

export const Appointment = ({ appointments, setAppointment }) => {

    const formatDate = (date) => {
        return moment(date).format('dddd, MMMM Do YYYY')
    }


    return <div>
        <ListGroup className="text-center">
            {appointments.days.sort((a, b) => {
                return moment(a.date).valueOf() - moment(b.date).valueOf()
                        }).map((day, i) => {
                return <ListGroupItem key={i}>
                    <div>{formatDate(day.date)}</div>
                    <div key={i} className="">
                        {day.timeOfDays.map((tod, j) => <Button size="sm" color="dark" className="m-1" key={j} onClick={() => setAppointment(tod)}>{tod.timeOfDay}</Button>)}
                    </div>
                </ListGroupItem>
            })}
        </ListGroup>
        <br />
   </div>
}  