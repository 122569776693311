import React from 'react';
import { faMap, faMapMarker, faClock, faCalendar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';

export const Confirmation = ({bookingDetails, appointmentCount}) => {
    return <div className="text-white">
        <h4>Booking Confirmation</h4>
        <h5>Please ensure that you arrive a maximum of 5 minutes before your allotted appointment time.</h5>
        <hr />
        <div>  
            <ul>
                <li className="uppercase"><strong>Appointment 1 - {(bookingDetails.siteFriendlyName || bookingDetails.site)} - {(bookingDetails.firstAppointmentLocationFriendlyName || bookingDetails.firstAppointmentLocation)}</strong></li>
                <li><FontAwesomeIcon icon={faMap}></FontAwesomeIcon> Map : <span onClick={(e) => window.open(bookingDetails.firstAppointmentWTW, "_blank")} className="HyperLink">Click Here</span></li>
                <li><FontAwesomeIcon icon={faMapMarker}></FontAwesomeIcon> Site Map :   <span onClick={(e) => window.open(bookingDetails.firstAppointmentMapUri, "_blank")} className="HyperLink">Site Map</span></li>
                <li><FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon> Arrival Date :    {bookingDetails.firstAppointmentDate} </li>
                <li><FontAwesomeIcon icon={faClock}></FontAwesomeIcon> Arrival Time :   {bookingDetails.firstAppointmentTimeSlot}</li>
            </ul>

            {appointmentCount > 1 && <><hr />

                <ul>
                    <li className="uppercase"><strong>Appointment 2 - {(bookingDetails.siteFriendlyName || bookingDetails.site)} - {(bookingDetails.secondAppointmentLocationFriendlyName || bookingDetails.secondAppointmentLocation)}</strong></li>
                    <li><FontAwesomeIcon icon={faMap}></FontAwesomeIcon> Map : <span onClick={(e) => window.open(bookingDetails.secondAppointmentWTW, "_blank")} className="HyperLink">Click Here</span></li>
                    <li><FontAwesomeIcon icon={faMapMarker}></FontAwesomeIcon> Site Map :   <span onClick={(e) => window.open(bookingDetails.secondAppointmentMapUri, "_blank")} className="HyperLink">Site Map</span></li>
                    <li><FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon> Arrival Date :  {bookingDetails.secondAppointmentDate} </li>
                    <li><FontAwesomeIcon icon={faClock}></FontAwesomeIcon> Arrival Time :  {bookingDetails.secondAppointmentTimeSlot}</li>
                </ul></>}

            <hr />
            <p><FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon> Please make a note of your bookings before closing the internet browser window or save a copy of the web page.</p> 
            <p>We will send confirmation of appointments to the mobile phone number that received the unique booking link.  </p> 
            <p>Info section: if any appointments need changing please call <a href="tel:020 7188 4040">020 7188 4040</a>. You cannot amend appointments through this portal.</p> 


                  
    </div>
</div>
} 