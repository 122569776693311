import React from 'react';
import Button from 'reactstrap/lib/Button';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import Label from 'reactstrap/lib/Label';

export const Accessibility = ({setAccessibility}) => { 

    return <div className="text-white">
        <form>
            <Label className="mb-1" title="Does anyone attending the appointment have restricted mobility, or use a walking aid, mobility scooter or wheelchair?">Does anyone attending the appointment have restricted mobility, or use a walking aid, mobility scooter or wheelchair?</Label>
            <ButtonGroup style={{width:"100%"}}>
                <Button color="light" title="Yes" onClick={() => setAccessibility('Yes')}>Yes</Button>
                <Button color="dark" title="No" onClick={() => setAccessibility('No')}>No</Button>
            </ButtonGroup>
        </form>
    </div>

}
