import React, { useState } from 'react';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';

export const Postcode = ({ personName, submitPostCode, validationType, isStaff, appointmentCount, appointmentType }) => {
    const [tempPostCode, setTempPostCode] = useState("");


    const getGuidelines = function () {
        if (appointmentType === "3rd") {
            return <p className="text-white">The COVID-19 vaccination includes a 3rd dose according to current government guidelines.</p>
        }

        if (appointmentType === "Booster" || appointmentType === "Second Booster") {
            if (isStaff) {
                return <p className="text-white">Book your flu and COVID-19 booster vaccines. As a member of Guy's and St Thomas' staff please boost your immunity this winter by having your flu jab and COVID-19 booster (given after 6 months).</p>
            }
            return <>
                <p className="text-white">Guy's and St Thomas' NHS Foundation Trust are inviting you to book your booster dose of the COVID-19 vaccine.  The vaccination includes a booster dose to be taken 6 months after second dose.</p>
            </>
        }

        if (appointmentCount > 1) {
            return <p className="text-white">The COVID-19 vaccination consists of two injections spaced 8 weeks apart. Both appointments will be booked together and to ensure you receive the best possible protection it's important you receive both doses.</p>
        }

        if (appointmentCount === 1) {
            return <p className="text-white">The COVID-19 vaccination consists of a single injection for most 12 to 17 year olds.</p>
        }
    }

    return <div className="text-white">
        <p>The Link you provided is unique to <strong>{personName}</strong> and can only be used once to book an appointment for this person.</p>

        {getGuidelines()}
        
        <p>If you are booking for <strong>{personName}</strong>, please enter their <b>{validationType}</b> to continue.</p>

        {isStaff ? <>
            <p>You should use the postcode for your address that is registered with Guy's and St Thomas'. If you have moved house and not told us you may need to enter the postcode of your previous address.</p>

            <p>If you do not know your postcode please check the Electronic Staff Record or speak to your manager who can check it for you.</p>
            <p>(Please ensure ESR has your up to date details.)</p>

            
        </> : <>
            {validationType === 'postcode' && <>
                    <p>You should use the postcode for the above person's address that is registered with Guy's and St Thomas' or their GP. We may have an old postcode of a previous address on file so you may need to provide this if the system does not recognise the one you provide.</p>
                    <p>If you have problems providing this information please telephone <a href="tel:020 7188 4040">020 7188 4040</a>.</p>
            </>}
            {validationType === 'surname' && <>
                <p>You should use your last name for verification.</p>
                <p>If you cannot proceed past this screen please call <a href="tel:020 7188 4040">020 7188 4040</a>.</p>
            </>}
        </>
        }

        <InputGroup>
            <Input placeholder={validationType === 'postcode' ? "Registered Postcode" : "Surname"} value={tempPostCode} onChange={(e) => setTempPostCode(e.target.value)} maxLength="10"></Input>
            <br /><br />
            <Button color="success" block onClick={() => submitPostCode(tempPostCode)}>Submit</Button>
        </InputGroup>

        <small>Please <strong>close</strong> this page if you are not booking for <strong>{personName}</strong>.  </small>
    </div>
}