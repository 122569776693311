import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <div className="App BaseBlue">
            <header className="baseBlue">
                <div className="GSTTLogo"></div>
            </header>

            <div>
                <App className="" />
            </div>


            <footer className="baseBlue">
               <div className="HFSLogo"></div>
            </footer>
        </div>
  </BrowserRouter>,
  rootElement);


