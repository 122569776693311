import React from 'react';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'reactstrap/lib/Button';

export const Error = ({ error }) => {
    return <div className="text-white">
        <h4>Sorry you cannot proceed right now</h4>
        <p>{error.guidanceText}</p>
        <p>{error.guidanceText1}</p>
        <p>{error.guidanceText2}</p>

        <Button color="link" title="Back" className="mt-3" onClick={(e) => window.location.reload(false)} > <FontAwesomeIcon icon={faChevronCircleLeft}></FontAwesomeIcon>&nbsp;back</Button>
    </div>
} 