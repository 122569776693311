import React from 'react';
import Button from 'reactstrap/lib/Button';

export const Warning = ({ warnings, confirmation, hasNHSNumber, isStaff }) => {
    return <>{!isStaff ? <div className="text-white">
        <h5>Please confirm</h5>

        <p>I consent to share the information I have provided in this booking portal with the National Immunisation and Vaccination Service (NIVS), NHS Digital and GP of the person being booked for their appointment.</p>

        <p>I will also bring in a list of current medications and (if applicable) anticoagulation record book of the person having their vaccine.</p>

        {warnings.length !== 0 && <p>As you answered the following questions, please read and confirm to proceed.</p>}
        {
            warnings.map((w, i) =>
                <div className="text-white mb-1" style={{ whiteSpace: "pre-wrap" }} key={i}><strong>{w.text}</strong> {w.answer.warningText()}</div>

            )
        }

        <p>I confirm that the information I have provided here is true and complete to the best of my knowledge.</p>

        <p>If you have question about how and why your information is collected and shared, please refer to our website <a href="https://www.guysandstthomas.nhs.uk" target="_blank" rel="noopener noreferrer">www.guysandstthomas.nhs.uk</a> and search for "your health records".</p>

        {!hasNHSNumber && <h5><b>We do not have your NHS number in our records, please ensure that you bring it to your vaccination appointment</b></h5>}

        <Button color="dark" block onClick={() => confirmation(true)}>I have read the above and I am happy to proceed with booking this vaccine appointment</Button>

    </div> : <div className="text-white">
            <h5>Privacy notice</h5>

            <p>When you register for the COVID-19 and/or flu vaccination, you will be asked to submit some information about yourself and your health.</p>

            <p>The information you submit will only be accessed by the Guy's and St Thomas' Occupational Health, Health Informatics and Vaccination and Asymptomatic Testing teams. The information will be also shared with Public Health England in order to support further vaccination planning.</p>

            <p>If you work for another healthcare organisation, they will only be notified that you have registered to receive the COVID-19 and/or flu Vaccine. None of the other information you provide here will be shared with your organisation's management team.</p>

            <p>By submitting your data, you are agreeing to the data to be shared with the National Immunisation Vaccine System, NHS Digital and your GP. For staff attending care homes, information on COVID-19 vaccination status may be shared with management under the Health and Social Care Act 2008 (Regulated Activities) (Amendment) (Coronavirus) Regulations 2021.</p>

            <p>For more information about your rights under the General Data Protection Regulation and Data Protection Act 2018 please see <a href="https://www.guysandstthomas.nhs.uk/patients-and-visitors/patients/your-care/health-records.aspx" target="_blank" rel="noopener noreferrer">www.guysandstthomas.nhs.uk</a>.</p>

            {warnings.length !== 0 && <p>As you answered the following questions, please read and confirm to proceed.</p>}
            {
                warnings.map((w, i) =>
                    <div className="text-white mb-1" style={{ whiteSpace: "pre-wrap" }} key={i}><strong>{w.text}</strong> {w.answer.warningText()}</div>

                )
            }

            {!hasNHSNumber && <h5><b>We do not have your NHS number in our records, please ensure that you bring it to your vaccination appointment</b></h5>}

            <Button color="dark" block onClick={() => confirmation(true)}>I have read the above and I am happy to proceed with booking this vaccine appointment</Button>
    </div>
    }
    </>


}